import React from 'react';
// images
import Image from '../assets/oswaldo.png';
import ImageGit from '../assets/github.png';
// icons
import { FaGithub, FaYoutube, FaDribbble } from 'react-icons/fa';
// type animation
import { TypeAnimation } from 'react-type-animation';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
import Fullstack from '../assets/Full+Stack.png';
import CV from '../assets/Oswaldo_Herrera_CV.pdf';

const Banner = () => {

  

  return (
    // min-h-[45vh] lg:min-h-[70vh]
    <section
      className=' flex items-center  section-general'
      id='home'
    >
      <div className='container mx-auto '>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* text */}
          <div className='flex-1 text-center font-secondary lg:text-left'>
            

            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[55px] font-bold leading-[0.8] lg:text-[55px]'
            >
              Oswaldo <span>Herrera</span>
            </motion.h1>
            <motion.div
              variants={fadeIn('up', 0.4)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'
            >
              <span className='text-white mr-4'>Soy </span>
              <TypeAnimation
                sequence={[
                  'FullStack',
                  2000,
                  'FrontEnd',
                  2000,
                  'Backend',
                  2000,
                  'Team Worker',
                  2000,
                ]}
                speed={50}
                className='text-accent'
                wrapper='span'
                repeat={Infinity}
              />
            </motion.div>
            <motion.p
              variants={fadeIn('up', 0.5)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-8 max-w-lg mx-auto lg:mx-0'
            >
              Soy de León Guanajuato, especializado en el desarrollo de aplicaciones web únicas. 
            </motion.p>
            <motion.div
              variants={fadeIn('up', 0.6)}
              initial='hidden'
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'
            >
              
              <a href={CV} download="CV_Oswaldo_Herrera.pdf" className='border-inherit text-white font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded inline-block'>
                C.V.
              </a>   
              
              <motion.div
                variants={fadeIn('up', 0.7)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
              >
                <a href='#' className='hidden'>
                  <FaYoutube />
                </a>
                <a href='https://github.com/oswaldo-herrera' target='_blank' className='hover-kodemia pt-2'>
                <img
                    className='object-scale-down h-16 w-20 '
                    src={ImageGit}
                    alt=''
                  />
                </a>                
                
                <a href='https://app.openbadges.me/view/#!/badge?badgeid=4366e280-dc1f-458e-a7f8-a0724fc670ea' target='_blank' className='hover-kodemia'>
                  <img
                    className='object-scale-down h-18 w-20 '
                    src={Fullstack}
                    alt=''
                  />
                  <div class="overlay"></div>
                </a>
               
              
              </motion.div>
              
                <a href='#' className='text-gradient btn-link hidden'>
                  My Portfolio
                </a>

            </motion.div >

            <div className='hidden'>
              {/* socials */}
              <motion.div
                variants={fadeIn('up', 0.7)}
                initial='hidden'
                whileInView={'show'}
                viewport={{ once: false, amount: 0.7 }}
                className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
              >
                <a href='#'>
                  <FaYoutube />
                </a>
                <a href='#'>
                  <FaGithub />
                </a>
                <a href='#'>
                  <FaDribbble />
                </a>
              </motion.div>

            </div>
            

          </div>
          {/* image */}
          <motion.div
            variants={fadeIn('down', 0.5)}
            initial='hidden'
            whileInView={'show'}
            className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'
          >
            <img src={Image} alt='' className='rounded-full opacity-40' />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
