import React from 'react';
// icon
import { BsArrowUpRight } from 'react-icons/bs';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
//imagenes
import ImageTechnology from '../assets/tecnologias_2.png';

// services data
const services = [
  {
    name: 'Diseño UI/UX',
    description:
      'Crear interfaces visualmente atractivas (UI) y experiencias de usuario intuitivas y agradables (UX) asegurando que sean funcionales, accesibles y estéticamente agradables.',
  },
  {
    name: 'Desarrollo',
    description:
      'Crear sistemas eficientes y funcionales. Involucra el diseño, codificación, pruebas y mantenimiento de software para resolver problemas específicos y mejorar la productividad.',
    
  },
  {
    name: 'Tecnologías',
    description:
      'Tengo experiencia en varias tecnologías por ejemplo: Python, Django, Tailwind, JavaScript, Node.js React.js etc.... Esto me permite desarrollar soluciones completas y eficientes.',
    image: '',
  },
  {
    name: 'Metodología Ágil Scrum',
    description:
      'Tengo experiencia trabajando en equipo bajo la metodología ágil Scrum, adaptándome rápidamente a cambios.',
  },
];

const Services = () => {
  return (
    <section className='section  chico' id='services'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text & image */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat mix-blend-lighten  '
          >
            <h2 className='h2 text-accent mb-6'>Lo que hago.</h2>
            <h3 className='h3 max-w-[455px] mb-16'>
             Soy un desarrollador que siempre esta dispuesto a enfrentar desafíos y encontrar soluciones creativas.
            </h3>
            <button className='btn btn-sm hidden'>See my work</button>
          </motion.div>
          {/* services */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 h-50 w-70'
          >
            
            {/* service list */}
            <div>
              {services.map((service, index) => {
                // destructure service
                const { name, description, link, image } = service;
                return (
                  <div
                    className='border-b border-white/20 h-[146px] mb-[38px] flex '
                    key={index}
                  >
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-2'>
                        {name}
                      </h4>
                      <p className='font-secondary leading-tight '>
                        {description}
                      </p>
                      {image && <img src={image} alt={name} className='m-0  items-start'  />}
                     
                    </div>
                    <div className='flex flex-col flex-1 items-end'>
                      <a
                        href='#'
                        className='btn w-9 h-9 mb-[42px] flex justify-center items-center hidden'
                      >
                        <BsArrowUpRight />
                      </a>
                      <a href='#' className='text-gradient text-sm hidden'>
                        {link}
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
           
          </motion.div>
         
        </div>
       
      </div>
    </section>
  );
};

export default Services;
