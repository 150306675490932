import React from 'react';
// countup
import CountUp from 'react-countup';
// intersection observer hook
import { useInView } from 'react-intersection-observer';
// motion
import { motion } from 'framer-motion';
// variant
import { fadeIn } from '../variants';
import Fullstack from '../assets/Full+Stack.png';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  return (
    <section className='section section-general' id='about' ref={ref}>
      <div className='container mx-auto mb-12'>
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen'>
          {/* img */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            // mix-blend-soft-light mix-blend-exclusion mix-blend-plus-darker mix-blend-lighten
            className=' flex-1 bg-about bg-contain bg-no-repeat lg:h-[640px] mix-blend-lighten bg-top'
          ></motion.div>
          {/* text */}
          <motion.div
            variants={fadeIn('left', 0.5)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 '
          >
            <h2 className='h2 text-accent'>Sobre Mi ...</h2>
            <h3 className='h3 mb-4'>
            Soy un fullstack apasionado que disfruta convertir ideas en realidades digitales, tanto en frontend como backend.
            </h3>
            <p className='mb-6'>
            Siempre estoy explorando nuevas tecnologías y me emociono con cada desafío que surge. Me gusta crear experiencias web que realmente marquen la diferencia.
            </p>
            {/* stats */}
            <div className='flex  gap-x-6 lg:gap-x-10 mb-12 mt-10 '>
              <div>
                <div className='text-[30px] lg:text-[40px] font-tertiary text-gradient mb-2'>
                 + {inView ? <CountUp start={0} end={3} duration={3} /> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  años de <br />
                  Experiencia
                </div>
              </div>
              <div>
                <div className='text-[30px] lg:text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={100} duration={3} /> : null}
                  %
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  team <br />
                  worker
                </div>
              </div>
              <div className='mb-10'>
                <div className='text-[30px] lg:text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={100} duration={3} /> : null}
                  %
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  autodidacta <br />
                  
                </div>
              </div>

              
            </div>

            {/* <div className='flex gap-x-6 lg:gap-x-10 mb-12 hidden'>
              
              <div className=' font-tertiary text-gradient mb-2 mx-auto lg:ml-2'>
                <a href='https://app.openbadges.me/view/#!/badge?badgeid=4366e280-dc1f-458e-a7f8-a0724fc670ea' target='_blank' className='hover-kodemia'>
                  <img
                    className='object-scale-down h-48 w-66 '
                    src={Fullstack}
                    alt=''
                  />
                  <div class="overlay"></div>
                </a>
              </div>
               
             
              
            </div> */}

            <div className='flex gap-x-8 items-center'>
              <button className='btn btn-lg hidden'>Contact me</button>
              <a href='#' className='text-gradient btn-link hidden'>
                My Portfolio
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
