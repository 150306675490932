import React, { useState } from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// img
import Img1 from '../assets/mobile_first.png';
import Img2 from '../assets/triada.png';
import Img3 from '../assets/ecommerce.png';
import ImageTechnology from '../assets/prueba.png';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const Work = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };


  return (
    <section className='section  section-general' id='work'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-12 '
          >
            {/* text */}
            <div>
              <h2 className='h2 leading-tight text-accent'>
                Mis Proyectos <br />
               
              </h2>
              <p className='max-w-sm mb-16'>
              Mis proyectos abarcan desde el desarrollo de aplicaciones web dinámicas hasta la creación de interfaces de usuario intuitivas y eficientes. He trabajado en diversas soluciones tecnológicas que mejoran la experiencia del usuario y optimizan procesos empresariales.
              </p>

              {/* Acordeón */}
              <div className="border border-gray-200 rounded-md">
                <button
                  className="accordion-button w-full text-left py-2 px-6  hover:bg-transparent focus:outline-none"
                  onClick={toggleAccordion}
                >
                <span className='pr-3'>Otros Proyectos</span>
                <FontAwesomeIcon icon={faChevronDown} />
                </button>
                <div className={`accordion-content p-6  border-gray-200 ${isOpen ? '' : 'hidden'}`}>
                  <ul>
                    <li>
                      <a href='https://dreamy-speculoos-231a89.netlify.app/' target='_blank' className='flex items-center space-x-2 hover:text-shadow'>
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      <span>Menu Digital</span>
                      </a>
                    </li>
                    <li>
                      <a href='https://fantastic-cannoli-5f7062.netlify.app/' target='_blank' className='flex items-center space-x-2 hover:text-shadow'>                      
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      <span>Landing Page</span>
                      </a>
                    </li>
                    <li>
                      <a href='https://gleeful-florentine-1ff601.netlify.app/' target='_blank' className='flex items-center space-x-2 hover:text-shadow'>
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      <span>Tarjeta de Presentación</span>
                      </a>
                    </li>
                    <li>
                      <a href='https://sweet-paletas-6e85d4.netlify.app/' target='_blank' className='flex items-center space-x-2 hover:text-shadow'>
                     
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      <span>Tweet</span>
                      </a>
                    </li>
                    <li>
                      <a href='https://hilarious-elf-a08d67.netlify.app/' target='_blank' className='flex items-center space-x-2 hover:text-shadow'>
                    
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      <span>Buscador</span>
                      </a>
                    </li>
                    <li>
                      <a href='https://zippy-haupia-2917c5.netlify.app/' target='_blank' className='flex items-center space-x-2 hover:text-shadow'>
                     
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                      <span>Lego Diseño PC</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            
            </div>


            {/* image segunda*/}
           
            <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl mb-3'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* img */}
              <img
                className='group-hover:scale-125 transition-all duration-500'
                src={Img1}
                alt=''
              />
              <div className='absolute bottom-0 left-0 w-full text-white p-4 z-50'>
              {/* pretitle */}
              {/* absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50 */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-32 lg:group-hover:bottom-24 transition-all duration-500 z-50'>
                <span className='text-gradient'>Mobile First</span>
              
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-12 transition-all duration-700 z-50 '>
                <span className='text-3xl text-white'>Batata Bit "Página de Bitcoins" </span>
              
                <a href='https://curious-blini-75d2e4.netlify.app/' target='blank' className='hover:text-shadow'> Ver proyecto </a>
              </div>
              </div>
            </div>
           
          </motion.div>


          <motion.div
            variants={fadeIn('left', 0.2)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-3'
          >
            {/* image principal*/}
            
            <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
              {/* overlay */}
              <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
              {/* img */}
              <img
                className='group-hover:scale-125 transition-all duration-500'
                src={Img2}
                alt=''
              />
              {/* pretitle */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-32 lg:group-hover:bottom-24 transition-all duration-500 z-50 '>
                <span className='text-gradient'>React JS / Node JS </span>
              </div>
              {/* title */}
              <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                <span className='text-3xl text-white'>Triada "Web para Músicos"  </span>
                <a href='https://www.triada.rocks/' target='blank' className='hover:text-shadow'> Ver proyecto </a>
              </div>
            </div>
            
          

          
           
              
              {/* image principal*/}
              <div className='group relative overflow-hidden border-2 border-white/50 rounded-xl'>
                {/* overlay */}
                <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                {/* img */}
                <img
                  className='group-hover:scale-125 transition-all duration-500'
                  src={Img3}
                  alt=''
                />
                {/* pretitle */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-32 lg:group-hover:bottom-24  transition-all duration-500 z-50'>
                  <span className='text-gradient'>FrontEnd / JavaScript</span>
                </div>
                {/* title */}
                <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-700 z-50'>
                  <span className='text-3xl text-white'>Productos "Carrito de compras"</span>
                  <a href='https://classy-cucurucho-737778.netlify.app/' target='blank' className='hover:text-shadow'> Ver proyecto</a>
                </div>
              </div>
              
     
            </motion.div>
        </div>
      </div>
      <br></br>
      <div className='flex justify-center'>
        <img className=' ' src={ImageTechnology} alt=''  />
      </div>
    </section>
  );
};

export default Work;
