import React from 'react';
import {useState} from 'react';
import config from './config.json';
import emailjs from 'emailjs-com';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';

const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { emailServiceID, emailTemplateID, emailUserID } = config;
    console.log('Service ID:', emailServiceID);
    console.log('Template ID:', emailTemplateID);
    console.log('User ID:', emailUserID);
    emailjs.send(
      emailServiceID,
      emailTemplateID,
      {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      },
      emailUserID
    )
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        message: ''
      });
    })
    .catch((err) => {
      console.log('FAILED...', err);
      alert('Failed to send message. Please try again.');
    });
  };


  return (
    <section className='py-5 lg:section section-general contact' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* text */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-white font-medium mb-2 tracking-wide'>
                ...
              </h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>
                Open <br/> to work!
              </h2>
            </div>
          </motion.div>
          {/* form */}
          <motion.form
            onSubmit={handleSubmit}
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start'
          >
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              name='name'
              value={formData.name}
              onChange={handleChange}
              placeholder='Tu nombre:'
              required
            />
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              placeholder='Tu email:'
              required
            />
            <textarea
              className='bg-transparent border-b py-2 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-0'
              name='message'
              value={formData.message}
              onChange={handleChange}
              placeholder='Tu mensaje:'
              required
            ></textarea>
            <button className='border-inherit text-white font-semibold  hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'>Send message</button>
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
