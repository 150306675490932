import React from 'react';
// images
import Logo from '../assets/FullStack.png';

const Header = () => {
  return (
    <header className='py-8'>
      <div className='container mx-auto'>
        <div className='flex  max-sm:items-center max-sm:flex-col md:justify-between lg:justify-between  '>
          {/* logo */}
          <a href='#' className=' mb-4  '>
            <img src={Logo} alt='' className='max-sm:ml-3 ' />
          </a>
          {/* button */}
          <a href='https://wa.link/95pvjy'>
          
            <button className='text-[15px] lg:text-[20px] border-inherit text-white font-semibold  hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded'>Open to Work</button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
